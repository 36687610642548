import React, { Fragment, useEffect, Component } from "react";
import "./App.css";
import "./assets/style.css";
import { Routes, Route } from "react-router-dom";
import { Navigate } from 'react-router-dom';
import HomePage from "./pages/Home";
import AboutUs from "./pages/AboutUs";
import ContactUs from "./pages/ContactUs";
import Doctors from "./pages/Doctor";
import Resources from "./pages/Resources";
import Privacy from "./components/PrivacyPolicy";
import TermsCondition from "./components/TermsAndCondition";
//import { BottomNavigation } from "@material-ui/core";
//import { BottomNavigationAction } from "@mui/material";
//import {AccountTreeOutlined, SecurityOutlined, ContactsOutlined } from "@material-ui/icons";
import { Helmet } from "react-helmet";
import ReactGA from 'react-ga';


// const HomePage = () => (
//   <div>
//     <h3>Home Page</h3>
//   </div>
// );
// const Doctors = () => (
//   <div>
//     <h3>Doctor's Page</h3>
//   </div>
// );
// const AboutUs = () => (
//   <div>
//     <h3>About Us Page</h3>
//   </div>
// );
// const Resources = () => (
//   <div>
//     <h3>Resources Page</h3>
//   </div>
// );
// const Social = () => (
//   <div>
//     <h3>Social Page</h3>
//   </div>
// );

function App(){
 
  const TRACKING_ID = "UA-189000861-1"; // OUR_TRACKING_ID
  ReactGA.initialize(TRACKING_ID);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <div>
      <Helmet>
        {/*HTML Meta Tags*/}
        <title>ONI Education Pvt Ltd</title>
        <meta
          name="description"
          content="ONI focuses on the core need of child development & delivers Visual reference, which provides the comfort to parents, 
                Deep science to focus on timing and deliver holistic health called LEAP and Digital Avatar helping doctors improve quality of 
                experience. Right from Conception, the first 1000 days of life, provides a unique window of opportunity for parents to set 
                the right trajectory of Physical, Cognitive and Immune Development of their child. Pregnant couples need timely re-assurance 
                that their child is safe & healthy."
        />

        {/* Google / Search Engine Tags */}
        <meta itemprop="name" content="ONI Education Pvt Ltd" />
        <meta
          itemprop="description"
          content="ONI focuses on the core need of child development & delivers Visual reference, which provides the comfort to parents, 
                Deep science to focus on timing and deliver holistic health called LEAP and Digital Avatar helping doctors improve quality of 
                experience. Right from Conception, the first 1000 days of life, provides a unique window of opportunity for parents to set 
                the right trajectory of Physical, Cognitive and Immune Development of their child. Pregnant couples need timely re-assurance 
                that their child is safe & healthy."
        />
        <meta
          itemprop="image"
          content="https://www.google.com/url?sa=i&url=https%3A%2F%2Fwww.onicares.com%2F&psig=AOvVaw0RDAzATJDXo4OYnBSLx4di&ust=1653762572544000&source=images&cd=vfe&ved=0CAwQjRxqFwoTCJjq4OengPgCFQAAAAAdAAAAABAT"
        />

        {/* Facebook Meta Tags */}
        <meta property="og:url" content="https://www.onicares.com" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="ONI Education Pvt Ltd" />
        <meta
          property="og:description"
          content="ONI focuses on the core need of child development & delivers Visual reference, which provides the comfort to parents, 
                Deep science to focus on timing and deliver holistic health called LEAP and Digital Avatar helping doctors improve quality of 
                experience. Right from Conception, the first 1000 days of life, provides a unique window of opportunity for parents to set the 
                right trajectory of Physical, Cognitive and Immune Development of their child. Pregnant couples need timely re-assurance that 
                their child is safe & healthy."
        />
        <meta
          property="og:image"
          content="https://www.google.com/url?sa=i&url=https%3A%2F%2Fwww.onicares.com%2F&psig=AOvVaw0RDAzATJDXo4OYnBSLx4di&ust=1653762572544000&source=images&cd=vfe&ved=0CAwQjRxqFwoTCJjq4OengPgCFQAAAAAdAAAAABAT"
        />

        {/* Twitter Meta Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="ONI Education Pvt Ltd" />
        <meta
          name="twitter:description"
          content="ONI focuses on the core need of child development & delivers Visual reference, which provides the comfort to parents,
                Deep science to focus on timing and deliver holistic health called LEAP and Digital Avatar helping doctors improve quality of 
                experience. Right from Conception, the first 1000 days of life, provides a unique window of opportunity for parents to set the 
                right trajectory of Physical, Cognitive and Immune Development of their child. Pregnant couples need timely re-assurance that 
                their child is safe & healthy."
        />
        <meta
          name="twitter:image"
          content="https://www.google.com/url?sa=i&url=https%3A%2F%2Fwww.onicares.com%2F&psig=AOvVaw0RDAzATJDXo4OYnBSLx4di&ust=1653762572544000&source=images&cd=vfe&ved=0CAwQjRxqFwoTCJjq4OengPgCFQAAAAAdAAAAABAT"
        />

        {/* Meta Tags Generated via http://heymeta.com */}
      </Helmet>

      <Fragment>
        <Routes>
          <Route exact path="/" element={<Navigate replace to="/Home" />} />
          <Route path="/Home" element={<HomePage />} />
          <Route path="/AboutUs" element={<AboutUs />} />
          <Route path="/Doctor" element={<Doctors />} />
          <Route path="/ContactUs" element={<ContactUs />} />
          <Route path="/PrivacyPolicy" element={<Privacy />} />
          <Route path="/TermsAndCondition" element={<TermsCondition />} />
        </Routes>
      </Fragment>

      {/* <Fragment>
        <BottomNavigation className="BoNav" showLabels>
          <BottomNavigationAction
            label="Terms and Condition"
            icon={<ContactsOutlined style={{ color: "black" }} />}
            style={{ color: "black" }}
          />
          <BottomNavigationAction
            label="Privacy Policy"
            icon={<SecurityOutlined style={{ color: "black" }} />}
            style={{ color: "black" }}
          />
          <BottomNavigationAction
            label="Site Map"
            icon={<AccountTreeOutlined style={{ color: "black" }} />}
            style={{ color: "black" }}
          />
        </BottomNavigation>
      </Fragment> */}
    </div>
  );
}

export default App;
