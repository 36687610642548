import { NavLink as Link } from 'react-router-dom';
import styled from 'styled-components';
  
export const Nav = styled.nav`
  background-image: linear-gradient(to right, rgba(255, 78, 11,0.6) , rgba(255, 255, 255,0.8));
  position:fixed;
  top:0;
  left:0;
  width:100%;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  height: 60px;
  display: flex;
  justify-content: space-between;
  /*padding: 0.2rem calc((100vw - 1000px) / 2);*/
  padding-right: 80px;
  padding-left: 80px;
  z-index: 12;
  @media (min-width:320px) and (max-width:480px){
    position:fixed;
    width:100vw;
    top:0;
  }
  @media (min-width:481px) and (max-width:1024px){
    position:fixed;
    width:100vw;
    top:0;
  }
  @media (min-width:1025px) and (max-width:1210px){
    position:fixed;
    width:100vw;
    top:0;
  }
`;
  
export const NavLink = styled(Link)`
  color: black;
  font-size: 1.2rem;
  font-weight:700;
  display: flex;
  align-items: center;
  text-decoration: none;
  margin-left: 50px;
  height: 100%;
  cursor: pointer;
  &:hover {
      color: black;
      text-decoration: underline;
      text-decoration-color: #f55014;
      text-decoration-thickness: 5px;
  }
  &.active {
      color: black;
      text-decoration: underline;
      text-decoration-color: #f55014;
      text-decoration-thickness: 5px;
  }
  @media (min-width:320px) and (max-width:480px){
    position:fixed;
    width:100vw;
    top:0;
    font-size: 1rem;
  }
  @media (min-width:481px) and (max-width:1024px){
    position:fixed;
    width:100vw;
    top:0;
    font-size: 1.2rem;
  }
`;
  
export const NavMenu = styled.div`
  display: flex;
  align-items: right;
  margin-right: -24px;
  /* Second Nav */
  /* margin-right: 24px; */
  /* Third Nav */
  /* width: 100vw;
  white-space: nowrap; */
  @media (min-width:1025px) and (max-width:1210px){
    img{
      width: 80px;
      height:80px;
    }
  }
`;
  
export const NavBtn = styled.nav`
  display: flex;
  align-items: center;
  margin-right: 24px;
  /* Third Nav */
  /* justify-content: flex-end;
  width: 100vw; */
  @media screen and (max-width: 768px) {
    display: none;
  }
`;
  
export const NavBtnLink = styled(Link)`
  border-radius: 4px;
  background: #808080;
  padding: 10px 22px;
  color: #000000;
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  /* Second Nav */
  margin-left: 24px;
  &:hover {
    transition: all 0.2s ease-in-out;
    background: #fff;
    color: #808080;
  }
`;