import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Nav, NavLink, NavMenu } from './Elements';
import { Container, NavDropdown, Navbar } from 'react-bootstrap'
import Logo from '../../assets/Logo.svg';
import '../../App.css';
import useAnalyticsEventTracker from '../../AnalyticsEventTracker';
import { useNavigate } from "react-router-dom";

const NavBar = () => {

  const navigate = useNavigate();
  const gaEventTracker = useAnalyticsEventTracker('Navbar');
  
  return (
    <div>
      <Navbar collapseOnSelect expand="lg">
      <Container>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav>
        <NavMenu>
            <img src={Logo}  alt="Logo" style={{justifyContent:"right", height:"60px"}} />
            <NavLink to='/' style={{fontSize:"2rem", textDecoration:"none", color:"white", marginLeft:"5px"}}>ONI</NavLink>
        </NavMenu>
        <NavMenu>
            <NavLink to='/Home' onClick={()=>gaEventTracker('Home')}>HOME</NavLink>
            {/* <NavLink to='/Doctor' onClick={()=>gaEventTracker('Doctor')}>DOCTOR</NavLink> */}
            <NavLink to='/AboutUs' onClick={()=>gaEventTracker('About Us')}>ABOUT US</NavLink>
            <NavLink to='/ContactUs' onClick={()=>gaEventTracker('Contact Us')}>CONTACT US</NavLink>
            <NavLink to='/TermsAndCondition' >
              <NavDropdown 
                id="nav-dropdown-dark-example"
                title="RESOURCES"
              >
                <NavDropdown.Item id ="navDropdownItem" onClick={() => navigate("/TermsAndCondition")}>Terms and Condition</NavDropdown.Item>
                <NavDropdown.Item id ="navDropdownItem" onClick={() => navigate("/PrivacyPolicy")}>Privacy Policy</NavDropdown.Item>
                {/* <NavDropdown.Item id ="navDropdownItem" onClick={() => navigate("/Sitemap.xml")}>Sitemap</NavDropdown.Item> */}
              </NavDropdown>
            </NavLink>
        </NavMenu>
      </Nav>
      </Navbar.Collapse>
      </Container>
      </Navbar>
    </div>
  );
};

export default NavBar;