import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from 'media-query-react';
import "../assets/HomeStyle.css";
import { Button } from "@material-ui/core";
import ArrowRightAltOutlinedIcon from "@material-ui/icons/ArrowRightAltOutlined";
import LeapBanner from "../components/LeapBanner/Leap";
import Navbar from "../components/NavBar/Navbar";
import MobileNavbar from "../components/NavBar/NavBar1";
import DesktopVideo from "../assets/DesktopVideo.mp4";
import ResponsiveVideo from "../assets/ResponsiveVideo.mp4";
import LeapImg from "../assets/Leapimg.png";
import MomsApp from '../components/MomsApp';
//import LeapBanner from '../components/LeapBanner/LeapBanner';
import {Animated} from "react-animated-css";
//import ArrowDropDownCircleOutlinedIcon from '@material-ui/icons/ArrowDropDownCircleOutlined';

//const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop)

function App() {
    // const [isDisplayed, setIsDisplayed]= useState(false);
    // useEffect(() => {
    //     setInterval(() => {
    //         setIsDisplayed(true);
    //     }, 2400);
    // }, []);

    const navigate = useNavigate();

    // const myRef1 = useRef(null)
    // const executeScroll1 = () => scrollToRef(myRef1)

    const isMobile = useMediaQuery({ query: 'min-width: 320px' && 'max-width: 480px' });
    const isTablet = useMediaQuery({ query: 'min-width: 481px' && 'max-width: 1024px' });
    const isDesktop =useMediaQuery({ query: 'min-width: 1025px' });

    return (
        <div>
            <div className="video_wrapper">
                {isDesktop && (
                    <div>
                        <Navbar />
                        <video autoPlay loop>
                            <source src={DesktopVideo} type="video/mp4" />
                        </video>
                    </div>
                )}
                
                {isMobile && (
                    <div>
                        <MobileNavbar />
                        <video className="responsiveVideo" autoPlay loop muted>
                            <source src={ResponsiveVideo} type="video/mp4" />
                        </video>
                    </div>
                )}

                {isTablet && (
                    <div>
                        <MobileNavbar />
                        <video className="responsiveVideo" autoPlay loop muted>
                            <source src={ResponsiveVideo} type="video/mp4" />
                        </video>
                    </div>
                )}
        
                
                <Animated animationIn="fadeInLeft" animationOut="fadeOut" isVisible={true}>
                <div className="div2">
                    {/*{isDisplayed &&*/}
                    <div className="div1_MP">
                        <h3 className="videoHeading" style={{fontWeight:"900"}}>FROM CONCEPTION TO BIRTH TO 2 YEARS OF LIFE</h3>
                        <h6 className="videoContent" style={{fontWeight:"700"}}>
                            ONI is a child development platform starting from preconception, infertility, 
                            covering 9 months of pregnancy and including the first 2 years of the child's life. 
                        </h6>
                        {/* <a href="#momsAppSection" style={{textDecoration:"none"}}>
                        <Button
                        variant="contained"
                        style={{
                            color: "white",
                            backgroundColor: "#f55014",
                            borderRadius: "50px",
                            width: "150px",
                            fontWeight:"900"
                        }}
                        className="btn1"
                        >
                        Download
                        </Button>
                        </a> */}
                        <Button
                        onClick={() => navigate("/AboutUs")}
                        variant="text"
                        endIcon={<ArrowRightAltOutlinedIcon style={{ color: "white"}} />}
                        style={{ marginLeft: "50px", color: "white",fontWeight:"900" }}
                        className="btn2"
                        >
                        See More
                        </Button>
                    </div>
                    {/*}}*/}
                    <div className="div2_MP">
                        <h1 className="leapImgHeading">TAKE THE</h1>
                        <img src={LeapImg} alt="Leapimg" />
                    </div>
                </div>
                </Animated>
                {/* { isDesktop && (
                <button onClick={executeScroll1} style={{backgroundColor:"transparent", border:"0px"}}>
                    <ArrowDropDownCircleOutlinedIcon className="downarrow" style={{color:"white", height:"80px", width:"80px"}} />
                </button>
                )}  */}
            </div>
            <div /*ref={myRef1}*/ className="leapDiv">
                <LeapBanner></LeapBanner>
            </div>
            {/* <div>
                <NewLeap></NewLeap>
            </div> */}
            {/* <div id="momsAppSection">
                <MomsApp></MomsApp>
            </div> */}
        </div>
    );
}
export default App;
