import React from 'react';
import Navbar from '../components/NavBar/Navbar';
import MobileNavbar from "../components/NavBar/NavBar1";
import '../assets/style.css';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import EmailIcon from '@material-ui/icons/Email';
import {Animated} from "react-animated-css";
import { useMediaQuery } from 'media-query-react';
import { Form } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

function ContactUs(){
    const isMobile = useMediaQuery({ query: 'min-width: 320px' && 'max-width: 480px' });
    const isTablet = useMediaQuery({ query: 'min-width: 481px' && 'max-width: 1024px' });
    const isDesktop =useMediaQuery({ query: 'min-width: 1025px' });

    return(
        <div>
            {isDesktop && (
                    <Navbar />
                )}
                
                {isMobile && (
                    <MobileNavbar />
                )}

                {isTablet && (
                    <MobileNavbar />
                )}
                <h2 className="contactUsMainHeading">CONTACT US</h2>
            {/* <Animated animationIn="fadeInLeft" animationOut="fadeOut" isVisible={true}>
            <div className="contactForm">
                <div className="contactFormGroup1">
                    <div className="contactFieldGroup">
                        <Form.Label htmlFor="inputFirstName">First Name</Form.Label>
                        <Form.Control type="text" id="inputFirstName" />
                    </div>
                    <div className="contactFieldGroup">
                        <Form.Label htmlFor="inputLastName">Last Name</Form.Label>
                        <Form.Control type="text" id="inputLastName" />
                    </div>
                </div>
                <div className="contactFormGroup2">
                    <div className="contactFieldGroup">
                        <Form.Label htmlFor="inputMail">Mail</Form.Label>
                        <Form.Control type="text" id="inputMail" />
                    </div>
                    <div className="contactFieldGroup">
                        <Form.Label htmlFor="inputPhone">Phone</Form.Label>
                        <Form.Control type="text" id="inputPhone" />
                    </div>
                </div>
                <div className="contactFormGroup3">
                <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                    <Form.Label>Message</Form.Label>
                    <Form.Control 
                        as="textarea" 
                        rows={3} 
                        placeholder="Write your message here" 
                        style={{borderRadius:"20px", borderBottomWidth:"6px", borderBottomColor:"#faaf93"}} 
                    />
                </Form.Group>
                </div>
                <Button id="contactFormButton">SEND</Button>
            </div>
            </Animated> */}
            <Animated animationIn="flipInX" animationOut="fadeOut" isVisible={true} style={{margin:'4%'}}>
            <div className="contactDetails">
                <div className="contactDetailsDiv1">
                    <h5 className="contactHeading">EMAIL</h5>
                    <h6 className="contactDetailsTag"><EmailIcon style={{color:"#f55014"}} />  support@onicares.com</h6>
                </div>
                <div className="contactDetailsDiv2">
                    <h5 className="contactHeading">STAY  CONNECTED</h5>
                    <div className="snslinks">
                            <a href="https://www.facebook.com/ONIcares/" target="_blank" className="snslink"><FacebookIcon style={{color:"#f55014"}} /></a> 
                            <a href="https://www.instagram.com/oni_cares" target="_blank" className="snslink"><InstagramIcon style={{color:"#f55014"}} /></a> 
                            <a href="https://in.linkedin.com/company/onicares/" target="_blank" className="snslink"><LinkedInIcon style={{color:"#f55014"}} /></a>
                    </div>
                </div>
                <div className="contactDetailsDiv3">
                    <h5 className="contactHeading">WHEN YOU CAN REACH US</h5>
                    <h6 className="contactDetailsTag">9:30AM to 7PM</h6>
                    <h6 className="contactDetailsTag">MONDAY TO SATURDAY</h6>
                </div>
            </div>
            </Animated>
        </div>
    );
}
export default ContactUs;