import React from 'react';
import '../assets/style.css';
import DocsAppImg1 from '../assets/DocsAppImgs/Doc1.jpg';
import DocsAppImg2 from '../assets/DocsAppImgs/Doc2.jpg';
import DocsAppImg3 from '../assets/DocsAppImgs/Doc3.jpg';
import DocsAppImg4 from '../assets/DocsAppImgs/Doc4.jpg';
import Navbar from "../components/NavBar/Navbar";
import { useMediaQuery } from 'media-query-react';
import MobileNavbar from "../components/NavBar/NavBar1";
import useAnalyticsEventTracker from '../AnalyticsEventTracker';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Carousel } from 'react-bootstrap';
import AppStoreImg from '../assets/AppStore.png';
import GooglePlayImg from '../assets/GooglePlay.jpg';
import {Animated} from "react-animated-css";

const Doctors = () => {
    const gaEventTracker = useAnalyticsEventTracker('Doctors');
    const isMobile = useMediaQuery({ query: 'min-width: 320px' && 'max-width: 480px' });
    const isTablet = useMediaQuery({ query: 'min-width: 481px' && 'max-width: 1024px' });
    const isDesktop =useMediaQuery({ query: 'min-width: 1025px' });
    return(
        <div>
        {isDesktop && (
                    <Navbar />
                )}
                
                {isMobile && (
                    <MobileNavbar />
                )}

                {isTablet && (
                    <MobileNavbar />
                )}
        <div className='doctorsContainer1'>
            <div className='doctorsSubcontainer1'>
                <div className='doctorsSubsubcontainer1'>
                    <div className="doctorsContentCarousel">
                        <Carousel variant="dark"  ride={"carousel"} controls={false} indicators={true} interval={4000} fade={false} className="doctorsCarouselCard">
                        <Carousel.Item>
                        <div className="doctorsContentBlock">
                            <h3 className="doctorsContentHeading">
                                The 3rd Pillar of Support:<br></br>
                                No more searching on the web
                            </h3>
                            <h6 className="doctorsText">
                                Focused platform for the first 1000 days of life - targeted community
                                to your practise helping digitise the ecosystem and build learning across.<br></br><br></br>
                                Pregnant couples don't have any formal parenting training. This leads to poor
                                #childdevelopment journey. This has an impact on society.
                            </h6>
                        </div>
                        </Carousel.Item>
                        <Carousel.Item>
                        <div className="doctorsContentBlock">
                            <h3 className="doctorsContentHeading">
                                Focus on Deep Science with quality visuals
                            </h3>
                            <h6 className="doctorsText">
                                #childdevelopment is a series of complex and well coordinated changes. Stating the facts and hence killing
                                myths are important. The content is currently under review with JCDC.<br></br><br></br>
                                Visualisation is important for the mother to feel secure Our focus is to deliver security
                                through a great visual experience.
                            </h6>
                        </div>
                        </Carousel.Item>
                        <Carousel.Item>
                        <div className="doctorsContentBlock">
                            <h3 className="doctorsContentHeading">
                                Future ready: <br></br>
                                Latest in Technology for Appointments / EMR / Telemedicine / Prescription
                            </h3>
                            <h6 className="doctorsText">
                                All the systems are based on the latest technology with minimum inputs required by you or your team.
                                Preloaded test for mothers based on their dates for better management of your time and reduced repeat visit.
                                Data and security - as compared to US guidelines.<br></br>
                                Single window of appointments- both in clinic and video consultations.<br></br>
                                Single window view of patient's current and past history.<br></br>
                                Simple click prescriptions, symptoms and recommendation tabs.
                            </h6>
                        </div>
                        </Carousel.Item>
                        <Carousel.Item>
                        <div className="doctorsContentBlock">
                            <h3 className="doctorsContentHeading">
                                Realistic HEALTH Index - LEAP Score
                            </h3>
                            <h6 className="doctorsText">
                                The importance of overall health of the mother - Lifestyle, Emotion and Physical fitness is proven.<br></br>
                                Helping the parent understand will get a long way in helping her benchmark the development of the baby better.<br></br>
                                A Weight gain chart, symptoms tracker, kick counter, which will help you in better assessment of the parent.<br></br>
                                Electronic Medical Records which will make discussion with your patients easier both in person and video
                                consultations.<br></br>
                            </h6>
                        </div>
                        </Carousel.Item>
                        <Carousel.Item>
                        <div className="doctorsContentBlock">
                            <h3 className="doctorsContentHeading">
                            The only commitment we want is you
                            </h3>
                            <h6 className="doctorsText">
                                Pregnant couples get a free one month trail of the education module.<br></br>
                                They can choose not to continue working with us after that.<br></br>
                                If the parents decide not to continue with the Education platform, the telemedicine continues to be free to
                                use between you and the parents.
                            </h6>
                        </div>
                        </Carousel.Item>
                    </Carousel>
                    </div>
                    {/* <Animated animationIn="fadeInLeft" animationOut="fadeOut" isVisible={true}>
                        <h1 className="appheading" tyle={{color:"black", fontWeight:"bolder"}}>KEEP TRACK OF APPOINTMENTS ANYTIME.</h1>
                    </Animated>
                    <Animated animationIn="fadeInRight" animationOut="fadeOut" isVisible={true}>
                        <h6 style={{color:"gray"}}>Doctors can keep track of appointments.</h6>
                    </Animated> */}
                    <h4 className="doctorsApplinks" style={{color:"black"}}>Link to Doctor's App:</h4>
                    <div className="doctorsDownloadlinks">
                    <Animated animationIn="flipInX" animationOut="fadeOut" isVisible={true}>
                        <a href="https://apps.apple.com/in/app/oni/id1548691942" target="_blank" onClick={()=>gaEventTracker('Download from AppStore')}>
                            <img src={AppStoreImg} alt="AppStore" className="downloadlink" />
                        </a>
                    </Animated>
                    <Animated animationIn="flipInX" animationOut="fadeOut" isVisible={true}>
                        <a href="https://play.google.com/store/apps/details?id=com.expertsmobileapp" target="_blank" onClick={()=>gaEventTracker('Download from GooglePlay')}>
                            <img src={GooglePlayImg} alt="GooglePlay" className="downloadlink" />
                        </a>
                    </Animated>
                    </div>
                </div>
            </div>
            <div className='doctorsSubcontainer2'>
            <Carousel variant="dark" ride={"carousel"} controls={false} indicators={false} interval={4000} fade={true}>
                    <Carousel.Item>
                        <img
                        className="d-block w-20 Doctorappphoto"
                        src={DocsAppImg1}
                        alt="First slide"
                        />
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                        className="d-block w-20 Doctorappphoto"
                        src={DocsAppImg2}
                        alt="First slide"
                        />
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                        className="d-block w-20 Doctorappphoto"
                        src={DocsAppImg3}
                        alt="First slide"
                        />
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                        className="d-block w-20 Doctorappphoto"
                        src={DocsAppImg4}
                        alt="First slide"
                        />
                    </Carousel.Item>
                </Carousel>
            </div>
        </div>
        {/* <br></br><br></br>
        <div className="aboutUsContentContainer">
                <div className="aboutUsContent">
                    <div className="contentBlock">
                        <h3 className="aboutUsContentHeading">
                            The 3rd Pillar of Support:<br></br>
                            No more searching on the web
                        </h3>
                        <h6 className="doctorsText">
                            Focused platform for the first 1000 days of life - targeted community
                            to your practise helping digitise the ecosystem and build learning across.<br></br><br></br>
                            Pregnant couples don't have any formal parenting training. This leads to poor
                            #childdevelopment journey. This has an impact on society.
                        </h6>
                        <hr></hr>
                    </div>
                    <div className="contentBlock">
                        <h3 className="aboutUsContentHeading">
                            Focus on Deep Science with quality visuals
                        </h3>
                        <h6 className="doctorsText">
                            #childdevelopment is a series of complex and well coordinated changes. Stating the facts and hence killing
                            myths are important. The content is currently under review with JCDC.<br></br><br></br>
                            Visualisation is important for the mother to feel secure Our focus is to deliver security
                            through a great visual experience.
                        </h6>
                        <hr></hr>
                    </div>
                    <div className="contentBlock">
                        <h3 className="aboutUsContentHeading">
                            Future ready: <br></br>
                            Latest in Technology for Appointments / EMR / Telemedicine / Prescription
                        </h3>
                        <h6 className="doctorsText">
                            All the systems are based on the latest technology with minimum inputs required by you or your team.
                            Preloaded test for mothers based on their dates for better management of your time and reduced repeat visit.
                            Data and security - as compared to US guidelines.<br></br>
                            Single window of appointments- both in clinic and video consultations.<br></br>
                            Single window view of patient's current and past history.<br></br>
                            Simple click prescriptions, symptoms and recommendation tabs.
                        </h6>
                        <hr></hr>
                    </div>
                    <div className="contentBlock">
                        <h3 className="aboutUsContentHeading">
                            Realistic HEALTH Index - LEAP Score
                        </h3>
                        <h6 className="doctorsText">
                            The importance of overall health of the mother - Lifestyle, Emotion and Physical fitness is proven.<br></br>
                            Helping the parent understand will get a long way in helping her benchmark the development of the baby better.<br></br>
                            A Weight gain chart, symptoms tracker, kick counter, which will help you in better assessment of the parent.<br></br>
                            Electronic Medical Records which will make discussion with your patients easier both in person and video
                            consultations.<br></br>
                        </h6>
                        <hr></hr>
                    </div>
                    <div className="contentBlock">
                        <h3 className="aboutUsContentHeading">
                        The only commitment we want is you
                        </h3>
                        <h6 className="doctorsText">
                            Pregnant couples get a free one month trail of the education module.<br></br>
                            They can choose not to continue working with us after that.<br></br>
                            If the parents decide not to continue with the Education platform, the telemedicine continues to be free to
                            use between you and the parents.
                        </h6>
                        <hr></hr>
                    </div>
                </div>
                </div> */}
            </div>
    );
}
export default Doctors;