import React, { useState, Component } from 'react';
import './Cards.css';
import Typography from '@material-ui/core/Typography';
<link href="https://fonts.googleapis.com/css2?family=Montserrat&display=swap" rel="stylesheet"></link>

export default class Leap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      width_l: "25%",
      width_e: "25%",
      width_a: "25%",
      width_p: "25%",
      activeCard: -1,
      screen_width:
        window.innerWidth > 0 ? window.innerWidth : window.screen.width,
    };
  }

  render() {
    return (
      <div style={{ height: "110vh", width: "100%",overflow:"clip" }}>
        {/* <div style={{width:'100%',position:'absolute',height:'100vh',backgroundColor:'rgba(0,0,0,0.5)'}}></div> */}
        {/*<Typography
          variant="h1"
          color="secondary"
          className="textTop"
          style={{
            margin: "auto",
            fontSize: "2rem",
            marginTop: "7%",
            marginLeft: "3%",
            display: "inline",
            fontWeight: "400",
            textShadow: "10px 10px 10px rgba(0, 0, 0, 0.60)",
            position: "absolute",
            zIndex: "10",
          }}
        >
          EPIGENETICS Highlights the Importance of ...{" "}
        </Typography>*/}
        {/* <Typography
          variant="h1"
          className="textBottom"
          color="secondary"
          style={{
            top: "185%",
            left: "45vw",
            display: "inline",
            fontSize: "2rem",
            whiteSpace: "pre",
            display: "inline",
            fontWeight: "400",
            textShadow: "10px 10px 10px rgba(0, 0, 0, 0.60)",
            position: "absolute",
            zIndex: "10",
            cursor: "pointer",
          }}
        >
          Check your Readiness LEAP SCORE for{" "}
          <strong style={{ fontSize: "2.6rem", fontWeight: "bold" }}>
            FREE !
          </strong>
        </Typography> */}
        {/* <div
          className="downArrow"
          style={{
            position: "absolute",
            top: "183vh",
            right: "4vw",
            zIndex: "10",
          }}
        >
          {" "} */}
          {/* <div className="arrow-container animate__animated animate__fadeInDown">
            <div className="arrow-2" style={{ top: "0vh" }}>
              <i
                className="fa fa-angle-down"
                onClick={() => this.props.slideTo({ target: { name: 2 } })}
              ></i>
            </div>
            <div
              className="arrow-1 animate__animated animate__hinge animate__infinite animate__zoomIn"
              style={{ marginLeft: "0.35vw", marginTop: "0.3vh" }}
            ></div>
          </div> */}
          {/* <div className="middle_right">
            <div className="scroll" onClick={() =>
              this.props.slideTo({ target: { name: 2 } })
            } />
          </div>
        </div> */}

        <div className="rowLeap" style={{ display: "flex", width: "100vw" }}>
          <div
            className="cardCol colLifestyle"
            style={{
              width:
                this.state.screen_width < 768
                  ? "100vw"
                  : `${this.state.width_l}`,
              height:
                this.state.screen_width < 768
                  ? `${this.state.width_l}`
                  : "auto",
              zIndex: "5",
            }}
            name="1"
            onClick={(e) => {
              if (this.state.screen_width < 768) {
                this.setState({
                  width_l: "55%",
                  width_e: "15%",
                  width_a: "15%",
                  width_p: "15%",
                });
              }
            }}
            onMouseEnter={(e) => {
              if (this.state.screen_width >= 768) {
                this.setState({
                  width_l: "55%",
                  width_e: "15%",
                  width_a: "15%",
                  width_p: "15%",
                });
              }
            }}
            onMouseLeave={(e) => {
              if (this.state.screen_width >= 768) {
                this.setState({
                  width_l: "25%",
                  width_e: "25%",
                  width_a: "25%",
                  width_p: "25%",
                });
              }
            }}
          >
            {/* <div style={{width:'100%',position:'absolute',height:'100vh',backgroundColor:'rgba(0,0,0,0.5)'}}> */}
            {this.state.width_l === "55%" ? (
              <div
                style={{ display: "flex", height: "100%", padding: "0vh 8vw" }}
              >
                <div
                  style={{
                    position: "absolute",
                    width:
                      this.state.screen_width < 768
                        ? "100vw"
                        : `${this.state.width_l}`,
                    height:
                      this.state.screen_width < 768
                        ? `${this.state.width_l}`
                        : "110%",
                    backgroundColor: "grey",
                    opacity: "0.4",
                    left: 0,
                    zIndex: "-1",
                  }}
                ></div>
                <div
                  style={{
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Typography
                    variant="h2"
                    color="primary"
                    align="left"
                    display="inline"
                    style={{
                      margin: "auto",
                      marginRight: "1vw",
                      paddingBottom: "15vh",
                      fontSize: "13rem",
                      color:"#f55014",
                      fontFamily: "Montserrat"
                    }}
                  >
                    L
                  </Typography>
                </div>
                <div
                  className="innerText"
                  style={{ margin: "auto", paddingTop: "23vh"}}
                >
                  <Typography
                    variant="h1"
                    color="primary"
                    display="inline"
                    style={{ fontSize: "6rem", fontWeight: "bold", color:"#f55014", fontFamily: "Montserrat" }}
                  >
                    IFESTYLE
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    color="secondary"
                    align="left"
                    style={{
                      fontSize: "1.8rem",
                      fontWeight: "400",
                      lineHeight: "3rem",
                      whiteSpace: "break-spaces",
                      marginTop: "1rem",
                      color:"black",
                      fontWeight:"bolder",
                      color:"white",
                      fontFamily: "Montserrat",
                      border:"1px solid white",
                      backgroundColor:"rgba(0,0,0,0.6)",
                      padding:"20px",
                      borderRadius:"20px",
                      boxShadow:"10px 10px 20px white"
                    }}
                  >
                    Lifestyle is the interest, opinions, behaviours and
                    behavioural orientations of an individual, group or culture.
                    <br />
                  </Typography>
                </div>
              </div>
            ) : (
              <div
                style={{
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Typography
                  variant="h2"
                  color="primary"
                  style={{
                    width: "fit-content",
                    margin: "auto",
                    paddingBottom: this.state.screen_width < 768 ? 0 : "15vh",
                    fontSize: "13rem",
                    color:"#f55014",
                    textShadow:"6px 6px 8px black",
                    fontFamily: "Montserrat"
                  }}
                >
                  L
                </Typography>
              </div>
            )}
            {/* </div> */}
          </div>

          <div
            className="cardCol colEmotion"
            style={{
              width:
                this.state.screen_width < 768
                  ? "100vw"
                  : `${this.state.width_e}`,
              height:
                this.state.screen_width < 768
                  ? `${this.state.width_e}`
                  : "auto",
              zIndex: "5",
            }}
            onClick={(e) => {
              if (this.state.screen_width < 768) {
                this.setState({
                  width_l: "15%",
                  width_e: "55%",
                  width_a: "15%",
                  width_p: "15%",
                });
              }
            }}
            onMouseEnter={(e) => {
              if (this.state.screen_width >= 768) {
                this.setState({
                  width_l: "15%",
                  width_e: "55%",
                  width_a: "15%",
                  width_p: "15%",
                });
              }
            }}
            onMouseLeave={(e) => {
              if (this.state.screen_width >= 768) {
                this.setState({
                  width_l: "25%",
                  width_e: "25%",
                  width_a: "25%",
                  width_p: "25%",
                });
              }
            }}
          >
            {this.state.width_e === "55%" ? (
              <div
                style={{ display: "flex", height: "100%", padding: "0vh 4vw" }}
              >
                <div
                  style={{
                    position: "absolute",
                    width:
                      this.state.screen_width < 768
                        ? "100vw"
                        : `${this.state.width_e}`,
                    height:
                      this.state.screen_width < 768
                        ? `${this.state.width_e}`
                        : "110%",
                    backgroundColor: "grey",
                    opacity: "0.4",
                    left: this.state.screen_width < 768 ? "0" : "15vw",
                    zIndex: "-1",
                  }}
                ></div>
                <div
                  style={{
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Typography
                    variant="h2"
                    color="primary"
                    align="left"
                    display="inline"
                    style={{
                      margin: "auto",
                      marginRight: "1vw",
                      paddingBottom: "15vh",
                      fontSize: "13rem",
                      color:"#f55014",
                      fontFamily: "Montserrat"
                    }}
                  >
                    E
                  </Typography>
                </div>
                <div
                  className="innerText"
                  style={{
                    margin: "auto",
                    paddingTop: "15vh",
                    whiteSpace: "pre",
                  }}
                >
                  <Typography
                    variant="h1"
                    color="secondary"
                    display="inline"
                    style={{ fontSize: "6rem", fontWeight: "bold", color:"#f55014", fontFamily: "Montserrat"}}
                  >
                    MOTIONAL
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    color="secondary"
                    align="left"
                    style={{
                      fontSize: "1.8rem",
                      fontWeight: "400",
                      lineHeight: "3rem",
                      marginTop: "1rem",
                      whiteSpace: "break-spaces",
                      color:"black",
                      fontWeight:"900",
                      color:"white",
                      fontFamily: "Montserrat",
                      border:"1px solid white",
                      backgroundColor:"rgba(0,0,0,0.6)",
                      padding:"20px",
                      borderRadius:"20px",
                      boxShadow:"10px 10px 20px white"
                    }}
                  >
                    A strong feeling deriving from one's circumstances, mood, or
                    relationships with others
                    <br />
                  </Typography>
                </div>
              </div>
            ) : (
              <div
                style={{
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Typography
                  variant="h2"
                  color="primary"
                  style={{
                    width: "fit-content",
                    margin: "auto",
                    paddingBottom: this.state.screen_width < 768 ? 0 : "15vh",
                    fontSize: "13rem",
                    color:"#f55014",
                    textShadow:"6px 6px 8px black",
                    fontFamily: "Montserrat"
                  }}
                >
                  E
                </Typography>
              </div>
            )}
          </div>

          <div
            className="cardCol colAnatomy"
            style={{
              width:
                this.state.screen_width < 768
                  ? "100vw"
                  : `${this.state.width_a}`,
              height:
                this.state.screen_width < 768
                  ? `${this.state.width_a}`
                  : "auto",
              zIndex: "5",
            }}
            onClick={(e) => {
              if (this.state.screen_width < 768) {
                this.setState({
                  width_l: "15%",
                  width_e: "15%",
                  width_a: "55%",
                  width_p: "15%",
                });
              }
            }}
            onMouseEnter={(e) => {
              if (this.state.screen_width >= 768) {
                this.setState({
                  width_l: "15%",
                  width_e: "15%",
                  width_a: "55%",
                  width_p: "15%",
                });
              }
            }}
            onMouseLeave={(e) => {
              if (this.state.screen_width >= 768) {
                this.setState({
                  width_l: "25%",
                  width_e: "25%",
                  width_a: "25%",
                  width_p: "25%",
                });
              }
            }}
          >
            {this.state.width_a === "55%" ? (
              <div
                style={{ display: "flex", height: "100%", padding: "0vh 2vw" }}
              >
                <div
                  style={{
                    position: "absolute",
                    width:
                      this.state.screen_width < 768
                        ? "100vw"
                        : `${this.state.width_a}`,
                    height:
                      this.state.screen_width < 768
                        ? `${this.state.width_a}`
                        : "110%",
                    backgroundColor: "grey",
                    opacity: "0.4",
                    left: this.state.screen_width < 768 ? "0" : "30vw",
                    zIndex: "-1",
                  }}
                ></div>
                <div
                  style={{
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Typography
                    variant="h2"
                    color="primary"
                    align="left"
                    display="inline"
                    style={{
                      width: "fit-content",
                      margin: "auto",
                      paddingBottom: "15vh",
                      fontSize: "13rem",
                      fontWeight: "bold",
                      color:"#f55014",
                      fontFamily: "Montserrat"
                    }}
                  >
                    A
                  </Typography>
                </div>

                <div
                  className="innerText"
                  style={{
                    margin: "auto",
                    paddingTop: "25vh",
                    whiteSpace: "pre",
                  }}
                >
                  <Typography
                    variant="h1"
                    color="secondary"
                    display="inline"
                    style={{
                      fontSize: "3.5rem",
                      fontWeight: "bold",
                      marginTop: "10%",
                      color:"#f55014",
                      fontFamily: "Montserrat"
                    }}
                  >
                    NATOMY & PHYSIOLOGY
                  </Typography>

                  <Typography
                    variant="subtitle2"
                    color="secondary"
                    align="left"
                    style={{
                      fontWeight: "400",
                      fontSize: "1.8rem",
                      lineHeight: "3rem",
                      marginTop: "1rem",
                      marginLeft:"20px",
                      whiteSpace: "break-spaces",
                      color:"black",
                      fontWeight:"900",
                      color:"white",
                      fontFamily: "Montserrat",
                      border:"1px solid white",
                      backgroundColor:"rgba(0,0,0,0.6)",
                      padding:"20px",
                      borderRadius:"20px",
                      boxShadow:"10px 10px 20px white",
                      width:"600px",
                    }}
                  >
                    Anatomy refers to the internal and external structures of
                    the body whereas physiology refers to the study of the
                    functions of those structures.
                  </Typography>
                </div>
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  height: "100%",
                  justifyContent: "center",
                }}
              >
                <Typography
                  variant="h2"
                  color="primary"
                  style={{
                    width: "fit-content",
                    margin: "auto",
                    paddingBottom: this.state.screen_width < 768 ? 0 : "15vh",
                    fontSize: "13rem",
                    color:"#f55014",
                    textShadow:"6px 6px 8px black",
                    fontFamily: "Montserrat"
                  }}
                >
                  A
                </Typography>
              </div>
            )}
          </div>

          <div
            className="cardCol colPhysical"
            style={{
              width:
                this.state.screen_width < 768
                  ? "100vw"
                  : `${this.state.width_p}`,
              height:
                this.state.screen_width < 768
                  ? `${this.state.width_p}`
                  : "auto",
              zIndex: "5",
            }}
            onClick={(e) => {
              if (this.state.screen_width < 768) {
                this.setState({
                  width_l: "15%",
                  width_e: "15%",
                  width_a: "15%",
                  width_p: "55%",
                });
              }
            }}
            onMouseEnter={(e) => {
              if (this.state.screen_width >= 768) {
                this.setState({
                  width_l: "15%",
                  width_e: "15%",
                  width_a: "15%",
                  width_p: "55%",
                });
              }
            }}
            onMouseLeave={(e) => {
              if (this.state.screen_width >= 768) {
                this.setState({
                  width_l: "25%",
                  width_e: "25%",
                  width_a: "25%",
                  width_p: "25%",
                });
              }
            }}
          >
            {this.state.width_p === "55%" ? (
              <div
                style={{ display: "flex", height: "100%", padding: "0vh 4vw" }}
              >
                <div
                  style={{
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <div
                    style={{
                      position: "absolute",
                      width:
                        this.state.screen_width < 768
                          ? "100vw"
                          : `${this.state.width_p}`,
                      height:
                        this.state.screen_width < 768
                          ? `${this.state.width_p}`
                          : "110%",
                      backgroundColor: "grey",
                      opacity: "0.4",
                      left: this.state.screen_width < 768 ? "0" : "45vw",
                      zIndex: "-1",
                    }}
                  ></div>
                  <Typography
                    variant="h2"
                    color="primary"
                    align="left"
                    display="inline"
                    style={{
                      margin: "auto",
                      fontSize: "13rem",
                      paddingBottom: "15vh",
                      fontWeight: "bold",
                      color:"#f55014",
                      fontFamily: "Montserrat"
                    }}
                  >
                    P
                  </Typography>
                </div>

                <div
                  className="innerText"
                  style={{
                    margin: "auto",
                    paddingTop: "19vh",
                    marginLeft: "0",
                  }}
                >
                  <Typography
                    variant="h1"
                    color="secondary"
                    display="inline"
                    style={{
                      fontSize: "3.5rem",
                      fontWeight: "bold",
                      marginTop: "10%",
                      color:"#f55014",
                      fontFamily: "Montserrat"
                    }}
                  >
                    HYSICAL FITNESS
                  </Typography>

                  <Typography
                    variant="subtitle2"
                    color="secondary"
                    align="left"
                    style={{
                      fontWeight: "400",
                      fontSize: "1.8rem",
                      lineHeight: "3rem",
                      marginTop: "1rem",
                      whiteSpace: "break-spaces",
                      color:"black",
                      fontWeight:"900",
                      color:"white",
                      fontFamily: "Montserrat",
                      border:"1px solid white",
                      backgroundColor:"rgba(0,0,0,0.6)",
                      padding:"20px",
                      borderRadius:"20px",
                      boxShadow:"10px 10px 20px white"
                    }}
                  >
                    Physical fitness is to the human body what fine tuning is to
                    an engine. It enables us to perform up to our potential.
                  </Typography>
                </div>
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  height: "100%",
                  justifyContent: "center",
                }}
              >
                <Typography
                  variant="h2"
                  color="primary"
                  style={{
                    width: "fit-content",
                    margin: "auto",
                    paddingBottom: this.state.screen_width < 768 ? 0 : "15vh",
                    fontSize: "13rem",
                    color:"#f55014",
                    textShadow:"6px 6px 8px black",
                    fontFamily: "Montserrat"
                  }}
                >
                  P
                </Typography>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}