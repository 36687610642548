import React from 'react';
import Navbar from '../components/NavBar/Navbar';
import MobileNavbar from "../components/NavBar/NavBar1";
import { useMediaQuery } from 'media-query-react';
import '../assets/style.css';
import Logo from '../assets/Logo.svg';
import Team from '../components/Team';
import {Animated} from "react-animated-css";

function AboutUs(){
    
    const isMobile = useMediaQuery({ query: 'min-width: 320px' && 'max-width: 480px' });
    const isTablet = useMediaQuery({ query: 'min-width: 481px' && 'max-width: 1024px' });
    const isDesktop =useMediaQuery({ query: 'min-width: 1025px' });

    return(
        <div>
            {isDesktop && (
                    <div>
                        <Navbar />
                    </div>
                )}
                
                {isMobile && (
                    <div>
                        <MobileNavbar />
                    </div>
                )}

                {isTablet && (
                    <div>
                        <MobileNavbar />
                    </div>
                )}
                <h1 className="aboutUsMainHeading">ABOUT US</h1>
            <div className='aboutUsContainer'>
                <div className="aboutUsHeadingDiv">
                    <Animated animationIn="fadeInLeft" animationOut="fadeOut" isVisible={true}>
                        <h2 className="aboutUsHeading1">WE ARE</h2>
                    </Animated>
                    <Animated animationIn="fadeInLeft" animationOut="fadeOut" isVisible={true}>
                        <h1 className="aboutUsHeading2">ONICARES</h1>
                    </Animated>
                </div>
                <div className="aboutUsLogoDiv">
                    <Animated animationIn="fadeInRight" animationOut="fadeOut" isVisible={true}>
                        <img src={Logo} alt="ONI Logo" className="aboutUsLogoImg" />
                    </Animated>
                </div>
            </div>
            <hr></hr>
            <div className="aboutUsContentContainer">
                <div className="aboutUsContent">
                    <div className="contentBlock">
                        <h3 className="aboutUsContentHeading">
                            "The First 1000 days"<br></br>
                            You can't build square buildings
                            on round foundations
                        </h3>
                        <h6 className="aboutUsText">
                            From 1 cell to 1 billion cells, 
                            <span style={{color:"#f55014", fontWeight:"900"}}> #childdevelopment</span> is a journey of phenomenal growth, 
                            from conception to 2 years of birth.
                        </h6>
                        <hr></hr>
                    </div>
                    <div className="contentBlock">
                        <h3 className="aboutUsContentHeading">
                            Timing is critical to development
                        </h3>
                        <h6 className="aboutUsText">
                            <span style={{color:"#f55014", fontWeight:"900"}}> #childdevelopment</span> is a series of
                            complex well coordinated changes
                            <span style={{color:"#f55014", fontWeight:"900"}}> -95%</span> of the approximately 4,500 organs
                            are formed by <span style={{color:"#f55014", fontWeight:"900"}}> week 10</span> and by
                            week 32 all organs are fully formed
                            - The Brain grows <span style={{color:"#f55014", fontWeight:"900"}}> 3X</span> in weight in the first
                            2 years of life and take upto <span style={{color:"#f55014", fontWeight:"900"}}> 70%</span> of nutrition
                            - Immunity matures in the first 2 years of life
                        </h6>
                        <hr></hr>
                    </div>
                    <div className="contentBlock">
                        <h3 className="aboutUsContentHeading">
                            Epigenetic's emphasises the role of the environment
                        </h3>
                        <h6 className="aboutUsText">
                            Mother is the provider to the baby
                            and hence her Lifestyle, Emotion,
                            Anatomy, and Physics Fitness
                            <span style={{color:"#f55014", fontWeight:"900"}}> (LEAP)</span> impacts the
                            development of the baby
                        </h6>
                        <hr></hr>
                    </div>
                    <div className="contentBlock">
                        <h3 className="aboutUsContentHeading">
                            ONI works as the third pillar <br></br><br></br>
                            To build the foundation<br></br> 
                            Take the LEAP
                        </h3>
                        <h6 className="aboutUsText">
                            <span style={{color:"#f55014", fontWeight:"900"}}> "It takes a village to raise a child" </span> <br></br>
                            ~ Hillary Clinton<br></br><br></br>
                            ONI works with your medico and family
                            to support the development of your
                            baby.
                        </h6>
                        <hr></hr>
                    </div>
                    <div className="contentBlock">
                        <h3 className="aboutUsContentHeading">
                            Download our app
                        </h3>
                        <h6 className="aboutUsText">
                            Focus on Deep science to help build the
                            right growth path of the baby
                            - Education through evidence based
                            information: Weekly updates
                            Tools to benchmark your health and the
                            development of the baby
                            -Digital & anytime access to your ObGyn
                            / Pediac - Electronic Medical Records,
                            Appointments, Video consultation
                            - Access of specialists related to the
                            first 1000 days of the life
                        </h6>
                        <hr></hr>
                    </div>
                    <div className="contentBlock">
                        <h3 className="aboutUsContentHeading">
                            ABOUT US
                        </h3>
                        <h6 className="aboutUsText1">
                            Right from Conception, the first 1000 days of life, 
                            provides a unique window of opportunity for parents to set the right trajectory of Physical, 
                            Cognitive and Immune Development of their child. Pregnant couples need timely re-assurance that their child is 
                            safe & healthy and hence rely on a. Social pillar & b. Medical pillar. 
                            Today, couples go through multiple moments of high anxiety because the changing lifestyle has led to overall 
                            poor health of parents. ONI uses technology to deliver the 3rd pillar of support and supplements both the social 
                            & the medical pillar. ONI focuses on the core need of childdevelopment & delivers a. Visual reference, 
                            which provides the comfort to parents b. Deep science to focus on timing and deliver holistic health called 
                            LEAP and c. Digital Avatar helping doctors improve quality of experience.
                        </h6>
                        <hr></hr>
                    </div>
                </div>
            </div>
            {/* <div className="teamDetails">
                <Team />
            </div> */}
        </div>
    );
}
export default AboutUs;